.booking-wrap{
    border: 1px solid #D1D1D1;
    margin-bottom: 50px;
    }
    .booking-wrap h3{
    font-size: 18px;
    font-weight: 700;
    color: #393939;
    text-align: left;
    background-color: #F3F3F3;
    padding: 20px 15px;
    }
    .booking-detail{
    padding: 20px 15px;
    }
    .light-label{
    color: #B8B8B8;
    font-size: 16px;
    font-weight: 500;
    }
    .actual-data{
    color: #393939;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    }
    .booking-detail > .row{
    margin-top: 10px;
    margin-bottom: 10px;
    }
    .react-datepicker-wrapper {
        width: 100% !important;
    }